var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-box",
    {
      staticStyle: { cursor: "pointer" },
      attrs: { flex: "row", align: _vm.align, justify: _vm.justify },
      on: {
        click: function($event) {
          return _vm.$emit("click", _vm.value)
        }
      }
    },
    [
      _c("div", [_vm._v(_vm._s(_vm.value))]),
      _c("img", {
        staticStyle: { "margin-left": "8px" },
        attrs: { src: require("../../assets/imgs/location.png"), height: "18" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }