//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PlanLocationl',
  props: {
    value: {},
    align: {
      "default": 'center' },

    justify: {
      "default": 'flex-start' } } };